<template>
  <div class="editor_wrapper mt-3">
    <b class="service_title" v-if="serviceName">{{ serviceName }}</b>
    <div v-show="editable">
      <div class="mb-2">
        <v-icon class="mr-2" color="black">mdi-clipboard-text-outline</v-icon>
        <b style="font-size:1.1rem;">{{ $t("common.examcontent") }}:</b>
      </div>
      <text-editor v-model="clinicalResult" />
      <div class="mb-2 mt-4">
        <v-icon class="mr-2" color="black">mdi-clipboard-file-outline</v-icon>
        <b style="font-size:1.1rem;">{{ $t("common.clinicaldata") }}:</b>
      </div>
      <text-editor v-model="doctorNote" />
      <v-btn
        block
        max-height="32"
        :loading="loading"
        color="#6166f5"
        class="white--text mt-2"
        style="font-size:0.8rem;"
        @click="updateContent"
        v-show="showBtnSave"
      >
        <v-icon class="mr-2" small>mdi-content-save-outline</v-icon
        >{{ $t("common.save") }}</v-btn
      >
    </div>
    <div v-show="!editable" class="editor_result_content">
      <div v-if="clinicalResult">
        <div class="mb-1">
          <v-icon class="mr-2" color="black">mdi-clipboard-text-outline</v-icon>
          <b style="font-size:1.1rem;">{{ $t("common.examcontent") }}:</b>
        </div>
        <div v-html="clinicalResult"></div>
      </div>
      <div v-else>
        <img class="img_book_cover" src="icons/book.png" />
        <p class="label_book_cover">
          {{ $t("episode.clinicaldatanotavailable") }}.
        </p>
      </div>
      <div v-if="doctorNote" class="mt-4">
        <div class="mb-1">
          <v-icon class="mr-2" color="black">mdi-clipboard-file-outline</v-icon>
          <b style="font-size:1.1rem;">{{ $t("common.clinicaldata") }}:</b>
        </div>
        <div v-html="doctorNote"></div>
      </div>
      <div class="pl-15 pr-15 mt-3" v-if="noteTitle && noteMessage">
        <p class="note_title">{{ noteTitle }}</p>
        <p class="note">{{ noteMessage }}</p>
      </div>
    </div>
    <status-buttons
      ref="StatusButtons"
      :episode="episode"
      @cancel="$emit('cancel')"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { EpisodeStatusType } from "@/plugins/contants";
import TextEditor from "@/components/ckeditor/TextEditor.vue";
import StatusButtons from "./StatusButtons.vue";
import moment from "moment";

export default {
  components: {
    TextEditor,
    StatusButtons,
  },
  props: ["episode"],
  watch: {
    episode: {
      deep: true,
      handler() {
        this.render();
      },
    },
    clinicalResult: {
      handler(val, old) {
        if (val != old) {
          this.showBtnSave = true;
          this.$refs.StatusButtons.disableFinishBtn();
        }
      },
    },
  },
  computed: {
    userID() {
      return this.$store.getters["Authen/getUser"].userID.toLowerCase();
    },
  },
  mounted() {
    this.render();
  },
  data() {
    return {
      showBtnSave: false,
      editable: false,
      loading: false,
      recordID: "",
      clinicalResult: "",
      doctorNote: "",
      serviceName: "",
      noteTitle: "",
      noteMessage: "",
    };
  },
  methods: {
    render() {
      if (!this.episode) return;
      this.clean();
      var { records, statusID, userAdmittedID } = this.episode;
      var record = (records && records[0]) || {};
      var {
        recordID,
        clinicalResult,
        doctorNote,
        userAppoint,
        service,
      } = record;
      if (
        statusID == EpisodeStatusType.CheckedIn &&
        this.userID != userAdmittedID
      ) {
        this.editable = true;
      }

      this.recordID = recordID || "";
      this.clinicalResult = clinicalResult || "";
      this.doctorNote = doctorNote || "";
      this.serviceName = service.serviceName || "";

      if (clinicalResult && !this.editable) {
        var { fullName, dateUpdated } = userAppoint;
        var dateStr = moment(dateUpdated).format("DD/MM/YYYY, h:mm:ss a");
        this.noteTitle = fullName;
        this.noteMessage = dateStr;
      }
    },
    async updateContent() {
      if (!this.episode) return;
      var { statusID, clinicID, episodeID } = this.episode;
      if (statusID != EpisodeStatusType.CheckedIn) return;
      var clinicalResult = this.clinicalResult;
      var doctorNote = this.doctorNote;
      this.loading = true;
      await this.$httpClient.put(
        `/owner/clinics/${clinicID}/episodes/${episodeID}/records/${this.recordID}`,
        null,
        { clinicalResult, doctorNote }
      );
      this.loading = false;
      // this.showSuccess("Content saved successfully.");
      this.showBtnSave = false;
      this.$refs.StatusButtons.enableFinishBtn();
    },
    clean() {
      this.showBtnSave = false;
      this.editable = false;
      this.recordID = "";
      this.clinicalResult = "";
      this.doctorNote = "";
      this.serviceName = "";
      this.noteTitle = "";
      this.noteMessage = "";
    },
  },
};
</script>

<style lang="scss">
.editor_wrapper {
  // .ck-editor__editable {
  //   min-height: calc(100vh - 230px);
  //   @media screen and (max-width: 819px) {
  //     min-height: calc(100vh - 285px);
  //   }
  // }
  .service_title {
    font-size: 1.1rem;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
}
.editor_result_content {
  * {
    max-width: 100%;
    line-height: 1.65rem;
  }
  overflow-x: scroll;
  table {
    border-collapse: collapse;
    margin: 10px 0px;
  }
  table,
  th,
  td {
    border: 1px solid #757575;
    padding: 6px;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  figure.image {
    margin: 0 auto;
  }
  p.note {
    font-size: 0.85rem;
    opacity: 0.8;
    text-align: center;
    line-height: 1.5rem;
  }
  p.note_title {
    font-size: 0.95rem;
    text-align: center;
    line-height: 1.2rem;
  }
}
</style>
